export default Object.freeze({
  ACTIVE: 'active',
  ALLOWED_USERS: 'allowed_users',
  BIDDERS: 'bidders',
  CONTRACT_PARAMETERS: 'contract_parameters',
  CONTRACT_PRIVACY_POLICY: 'contract_privacy_policy',
  CONTRACT_SPECIFIC_CONDITION: 'contract_specific_condition',
  CONTRACT_STATUS: 'contract_status',
  CONTRACT_TERMS_OF_SERVICE: 'contract_terms_of_service',
  DIFF: 'diff',
  EMPTY: 'empty',
  FEES: 'fees',
  LOCAL_REGISTRATION_ID: 'local_registration_id',
  NOTE: 'note',
  ORGANIZATION: 'organization',
  PLAINPASSWORD: 'plainPassword',
  PRESIZED_DIVS: 'presized_divs',
  PUBLISH: 'publish',
  ROLE: 'role',
  SELLERS_JSON_ORGANIZATION: 'sellers_json_organization',
  SERVICE_STATUS: 'service_status',
  START_AT: 'start_at',
  TAGGING: 'tagging',
  TERMINATED_AT: 'terminated_at',
  UNLOCK: 'unlock',
  USER_GROUP: 'user_group',
  VALIDATED: 'validated',
  VIOUSLY_AFFILIATE_ID: 'viously_affiliate_id'
})
