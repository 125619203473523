import store from '@store'
import { i18n } from '@i18n'
import views from '@enums/views'
import aclActions from '@enums/aclActions'
import aclObjects from '@enums/aclObjects'
import endpoints from '@enums/endpoints'
import statusCode from '@enums/statusCode'

const ContractsList = () =>
  import(/* webpackChunkName: "contracts-list" */ '@views/contracts/ContractsList.vue')
const ContractsManager = () =>
  import(/* webpackChunkName: "contracts-manager" */ '@views/contracts/ContractsManager.vue')
const ContractsToSign = () =>
  import(/* webpackChunkName: "contracts-to-sign" */ '@views/contracts/ContractsToSign.vue')

export default [
  {
    path: `/${views.CONTRACT_TEMPLATES}`,
    name: views.CONTRACT_TEMPLATES,
    component: ContractsList,
    meta: {
      appBreadcrumb: [
        {
          text: 'ioTemplates',
          active: true
        }
      ],
      action: aclActions.LIST,
      object: aclObjects.CONTRACT
    }
  },
  {
    path: `/${views.CONTRACT_TEMPLATES}/edit/:id(\\d+)`,
    name: views.CONTRACT_TEMPLATES_EDIT,
    component: ContractsManager,
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch('contracts/fetchItem', `${endpoints.CONTRACTS}/${to.params.id}`)
        next()
      } catch (error) {
        error?.response?.status === statusCode.UNAUTHORIZED
          ? next(false)
          : next({
            name: views.RESOURCE_NOT_FOUND,
            params: { target: views.CONTRACT_TEMPLATES_EDIT }
          })
      }
    },
    meta: {
      appBreadcrumb: [
        {
          text: 'ioTemplates',
          to: { name: views.CONTRACT_TEMPLATES }
        },
        {
          text: () => `${i18n.t('common.version')} #${store.state.contracts.editedItem?.version}`,
          active: true
        }
      ],
      action: aclActions.GET,
      object: aclObjects.CONTRACT
    }
  },
  {
    path: `/${views.CONTRACTS_TO_SIGN}`,
    name: views.CONTRACTS_TO_SIGN,
    component: ContractsToSign,
    meta: {
      appBreadcrumb: [
        {
          text: 'iosToSign',
          active: true
        }
      ],
      action: aclActions.SIGNATORY,
      object: aclObjects.CONTRACT
    }
  }
]
