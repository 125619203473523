import axios from '@axios'
import endpoints from '@enums/endpoints'

export default {
  /**
   * Fetch organizations values
   * @param {Object} params
   * @returns {Promise<AxiosResponse<any>>}
   */
  getOrganizations (params) {
    return axios.get(endpoints.ORGANIZATIONS, { params })
  },
  /**
   * Get organization values
   * @param {string} organizationIRI
   * @returns {Promise<AxiosResponse<any>>}
   */
  getOrganization (organizationIRI) {
    return axios.get(organizationIRI)
  },
  /**
   * Update organization values
   * @param {string} organizationIRI
   * @param {Object} data
   * @returns {Promise<AxiosResponse<any>>}
   */
  updateOrganization (organizationIRI, data) {
    return axios.patch(organizationIRI, data)
  },
  /**
   * Create organization
   * @param {Object} data
   * @returns {Promise<AxiosResponse<any>>}
   */
  createOrganization (data) {
    return axios.post(endpoints.ORGANIZATIONS, data)
  },
  /**
   * Resend organization invitation
   * @param {string} organizationInvitationIRI
   * @returns {Promise<AxiosResponse<any>>}
   */
  resendOrganizationInvitation (organizationInvitationIRI, data) {
    return axios.patch(endpoints.ORGANIZATION_INVITATIONS_RESEND.replace('{{organizationInvitationIRI}}', organizationInvitationIRI), data)
  },
  /**
   * Post organization invitation
   * @param {string} email
   * @param {string} organization
   * @returns {Promise<AxiosResponse<any>>}
   */
  organizationInvitations (email, organization) {
    return axios.post(endpoints.ORGANIZATION_INVITATIONS, {
      email,
      organization
    })
  },
  /**
   * Fetch organization invitations values
   * @param {Object} params
   * @returns {Promise<AxiosResponse<any>>}
   */
  getOrganizationInvitations (params) {
    return axios.get(endpoints.ORGANIZATION_INVITATIONS, { params })
  },
  /**
   * Get organization invitation
   * @param {string} invitationIRI
   * @returns {Promise<AxiosResponse<any>>}
   */
  getOrganizationInvitation (invitationIRI) {
    return axios.get(invitationIRI)
  },
  /**
   * Unlink account for organization
   * @param {string} userIRI
   * @param {string} organizationIRI
   * @returns {Promise<AxiosResponse<any>>}
   */
  unlinkAccount (userIRI, organizationIRI) {
    return axios.post(endpoints.ORGANIZATION_EXCLUSIONS, {
      user: userIRI,
      organization: organizationIRI
    })
  },
  /**
   * Check the organizations contracts state
   * @param {Object} params
   * @returns {Promise<AxiosResponse<any>>}
   */
  checkOrganizationsContracts (params) {
    return axios.get(endpoints.ORGANIZATIONS_CHECK_CONTRACTS, { params })
  }
}
