import views from '@enums/views'
import store from '@store'
import endpoints from '@enums/endpoints'
import statusCode from '@enums/statusCode'
import aclActions from '@enums/aclActions'
import aclObjects from '@enums/aclObjects'
import aclFields from '@enums/aclFields'

const WebsiteConfigurationTabs = () =>
  import(/* webpackChunkName: "website-configuration-tabs" */ '@views/website-configuration/WebsiteConfigurationTabs.vue')

const WebsiteConfigurationSlots = () =>
  import(/* webpackChunkName: "website-configuration-slots" */ '@views/website-configuration/WebsiteConfigurationSlots.vue')

const WebsiteConfigurationInImage = () =>
  import(/* webpackChunkName: "website-configuration-in-image" */ '@views/website-configuration/WebsiteConfigurationInImage.vue')

const WebsiteConfigurationPurge = () =>
  import(/* webpackChunkName: "website-configuration-purge" */ '@views/website-configuration/WebsiteConfigurationPurge.vue')

const WebsiteConfigurationConsumption = () =>
  import(/* webpackChunkName: "website-configuration-consumption" */ '@views/website-configuration/WebsiteConfigurationConsumption.vue')

const WebsiteConfigurationScripts = () =>
  import(/* webpackChunkName: "website-configuration-scripts" */ '@views/website-configuration/WebsiteConfigurationScripts.vue')

const WebsiteConfigurationCss = () =>
  import(/* webpackChunkName: "website-configuration-css" */ '@views/website-configuration/WebsiteConfigurationCss.vue')

const WebsiteConfigurationDynamic = () =>
  import(/* webpackChunkName: "website-configuration-dynamic" */ '@views/website-configuration/WebsiteConfigurationDynamic.vue')

const WebsiteConfigurationNoAds = () =>
  import(/* webpackChunkName: "website-configuration-no-ads" */ '@views/website-configuration/WebsiteConfigurationNoAds.vue')

const WebsiteConfigurationBidders = () =>
  import(/* webpackChunkName: "website-configuration-bidders" */ '@views/website-configuration/WebsiteConfigurationBidders.vue')

const WebsiteConfigurationAdsTxt = () =>
  import(/* webpackChunkName: "website-configuration-ads-txt" */ '@views/website-configuration/WebsiteConfigurationAdsTxt.vue')

const WebsiteConfigurationPresizedDivs = () =>
  import(/* webpackChunkName: "website-configuration-presized-divs" */ '@views/website-configuration/WebsiteConfigurationPresizedDivs.vue')

/**
 * Display website configuration breadcrumb
 * @param {string} label
 * @returns {Array}
 */
const breadcrumb = label => [
  {
    text: 'websites',
    to: { name: views.WEBSITES }
  },
  {
    text: () => store.getters['websites/editedItemName'],
    to: () => ({
      name: views.WEBSITES_EDIT,
      params: {
        id: store.getters['websites/editedItemId']
      }
    })
  },
  {
    text: 'configurations',
    to: () => ({
      name: views.WEBSITES_EDIT_CONFIGS,
      params: {
        id: store.getters['websites/editedItemId']
      }
    })
  },
  {
    text: label,
    active: true
  }
]

const websiteConfigurationPath = `/${views.WEBSITES}/configuration/:website_id(\\d+)/version/:configuration_id(\\d+)`
const websiteConfigurationRequest = async (to, from, next) => {
  try {
    const requests = [store.dispatch('websites/fetchItem', `${endpoints.WEBSITES}/${to.params.website_id}`)]
    if (to.params.configuration_id) {
      requests.push(store.dispatch('websiteConfig/fetchWebsiteConfig', `${endpoints.WEBSITE_CONFIGS}/${to.params.configuration_id}`))
    }
    await Promise.all(requests)

    // Lock website config
    if (to.meta.canBeLocked) await store.dispatch('websiteConfig/lock')

    next()
  } catch (error) {
    error?.message?.status === statusCode.UNAUTHORIZED
      ? next(false)
      : next({
        name: views.RESOURCE_NOT_FOUND,
        params: { target: views.WEBSITES_EDIT }
      })
  }
}

const isflashbidProduct = (to, from, next) => (store.getters['websites/flashbidProduct']
  ? next()
  : next({ name: views.WEBSITES }))

export default [
  {
    path: websiteConfigurationPath,
    name: views.WEBSITE_CONFIGURATION_EDIT,
    redirect: { name: views.WEBSITE_CONFIGURATION_SLOTS },
    component: WebsiteConfigurationTabs,
    beforeEnter: websiteConfigurationRequest,
    children: [
      {
        path: 'slots',
        name: views.WEBSITE_CONFIGURATION_SLOTS,
        component: WebsiteConfigurationSlots,
        beforeEnter: isflashbidProduct,
        meta: {
          appBreadcrumb: breadcrumb('slots'),
          canBeLocked: true,
          action: aclActions.GET,
          object: aclObjects.WEBSITE_CONFIG,
          field: aclFields.TAGGING
        }
      },
      {
        path: 'in-image',
        name: views.WEBSITE_CONFIGURATION_IN_IMAGE,
        component: WebsiteConfigurationInImage,
        beforeEnter: isflashbidProduct,
        meta: {
          appBreadcrumb: breadcrumb('inImage'),
          canBeLocked: true,
          action: aclActions.GET,
          object: aclObjects.WEBSITE_CONFIG,
          field: aclFields.TAGGING
        }
      },
      {
        path: 'purge',
        name: views.WEBSITE_CONFIGURATION_PURGE,
        component: WebsiteConfigurationPurge,
        beforeEnter: isflashbidProduct,
        meta: {
          appBreadcrumb: breadcrumb('purge'),
          canBeLocked: true,
          action: aclActions.GET,
          object: aclObjects.WEBSITE_CONFIG,
          field: aclFields.TAGGING
        }
      },
      {
        path: 'consumption',
        name: views.WEBSITE_CONFIGURATION_CONSUMPTION,
        component: WebsiteConfigurationConsumption,
        beforeEnter: isflashbidProduct,
        meta: {
          appBreadcrumb: breadcrumb('consumption'),
          canBeLocked: true,
          action: aclActions.GET,
          object: aclObjects.WEBSITE_CONFIG,
          field: aclFields.TAGGING
        }
      },
      {
        path: 'scripts',
        name: views.WEBSITE_CONFIGURATION_SCRIPTS,
        component: WebsiteConfigurationScripts,
        beforeEnter: isflashbidProduct,
        meta: {
          appBreadcrumb: breadcrumb('scripts'),
          canBeLocked: true,
          action: aclActions.GET,
          object: aclObjects.WEBSITE_CONFIG,
          field: aclFields.TAGGING
        }
      },
      {
        path: 'css',
        name: views.WEBSITE_CONFIGURATION_CSS,
        component: WebsiteConfigurationCss,
        beforeEnter: isflashbidProduct,
        meta: {
          appBreadcrumb: breadcrumb('css'),
          canBeLocked: true,
          action: aclActions.GET,
          object: aclObjects.WEBSITE_CONFIG,
          field: aclFields.TAGGING
        }
      },
      {
        path: 'dynamic',
        name: views.WEBSITE_CONFIGURATION_DYNAMIC,
        component: WebsiteConfigurationDynamic,
        beforeEnter: isflashbidProduct,
        meta: {
          appBreadcrumb: breadcrumb('dynamic'),
          canBeLocked: true,
          action: aclActions.GET,
          object: aclObjects.WEBSITE_CONFIG,
          field: aclFields.TAGGING
        }
      },
      {
        path: 'no-ads',
        name: views.WEBSITE_CONFIGURATION_NO_ADS,
        component: WebsiteConfigurationNoAds,
        beforeEnter: isflashbidProduct,
        meta: {
          appBreadcrumb: breadcrumb('noAds'),
          canBeLocked: true,
          action: aclActions.GET,
          object: aclObjects.WEBSITE_CONFIG,
          field: aclFields.TAGGING
        }
      }
    ]
  },
  {
    path: `${websiteConfigurationPath}/bidders`,
    name: views.WEBSITE_CONFIGURATION_BIDDERS,
    component: WebsiteConfigurationBidders,
    beforeEnter: websiteConfigurationRequest,
    meta: {
      appBreadcrumb: breadcrumb('bidders'),
      canBeLocked: true,
      action: aclActions.GET,
      object: aclObjects.WEBSITE_CONFIG,
      field: aclFields.BIDDERS
    }
  },
  {
    path: `${websiteConfigurationPath}/ads-txt`,
    name: views.WEBSITE_CONFIGURATION_ADS_TXT,
    component: WebsiteConfigurationAdsTxt,
    beforeEnter: websiteConfigurationRequest,
    meta: {
      appBreadcrumb: breadcrumb('adsTxt'),
      canBeLocked: true,
      action: aclActions.GET,
      object: aclObjects.WEBSITE_CONFIG_ADSTXT
    }
  },
  {
    path: `${websiteConfigurationPath}/presized-divs`,
    name: views.WEBSITE_CONFIGURATION_PRESIZED_DIVS,
    component: WebsiteConfigurationPresizedDivs,
    beforeEnter: websiteConfigurationRequest,
    meta: {
      appBreadcrumb: breadcrumb('presizedDivs'),
      action: aclActions.GET,
      object: aclObjects.WEBSITE_CONFIG,
      field: aclFields.PRESIZED_DIVS
    }
  }
]
