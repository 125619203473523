import store from '@store'
import views from '@enums/views'
import endpoints from '@enums/endpoints'
import statusCode from '@enums/statusCode'
import aclActions from '@enums/aclActions'
import aclObjects from '@enums/aclObjects'
import aclFields from '@enums/aclFields'

const OrganizationsList = () =>
  import(/* webpackChunkName: "organizations-list" */ '@views/organizations/OrganizationsList.vue')
const OrganizationsTabs = () =>
  import(/* webpackChunkName: "organizations-tabs" */ '@views/organizations/OrganizationsTabs.vue')
const OrganizationsManager = () =>
  import(/* webpackChunkName: "organizations-manager" */ '@views/organizations/OrganizationsManager.vue')
const OrganizationsAccounts = () =>
  import(/* webpackChunkName: "organizations-accounts" */ '@views/organizations/OrganizationsAccounts.vue')
const OrganizationsWebsites = () =>
  import(/* webpackChunkName: "organizations-websites" */ '@views/organizations/OrganizationsWebsites.vue')

const appBreadcrumb = [
  {
    text: 'organizations',
    to: { name: views.ORGANIZATIONS }
  },
  {
    text: () => store.getters['organizations/editedItemName'],
    active: true
  }
]

export default [
  {
    path: `/${views.ORGANIZATIONS}`,
    name: views.ORGANIZATIONS,
    component: OrganizationsList,
    meta: {
      appBreadcrumb: [
        {
          text: 'organizations',
          active: true
        }
      ],
      action: aclActions.LIST,
      object: [aclObjects.ORGANIZATION, aclObjects.ALL_ORGANIZATIONS]
    }
  },
  {
    path: `/${views.ORGANIZATIONS}/create`,
    name: views.ORGANIZATIONS_CREATE,
    component: OrganizationsManager,
    props: {
      action: 'create'
    },
    meta: {
      appBreadcrumb: [
        {
          text: 'organizations',
          to: { name: views.ORGANIZATIONS }
        },
        {
          text: 'create',
          active: true
        }
      ],
      action: aclActions.CREATE,
      object: aclObjects.ORGANIZATION
    }
  },
  {
    path: `/${views.ORGANIZATIONS}/edit/:id(\\d+)`,
    redirect: { name: views.ORGANIZATIONS_EDIT_GENERAL },
    name: views.ORGANIZATIONS_EDIT,
    component: OrganizationsTabs,
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch('organizations/fetchItem', `${endpoints.ORGANIZATIONS}/${to.params.id}`)
        next()
      } catch (error) {
        error?.response?.status === statusCode.UNAUTHORIZED
          ? next(false)
          : next({
            name: views.RESOURCE_NOT_FOUND,
            params: { target: views.ORGANIZATIONS_EDIT }
          })
      }
    },
    children: [
      {
        path: '',
        name: views.ORGANIZATIONS_EDIT_GENERAL,
        component: OrganizationsManager,
        props: {
          noContract: true
        },
        meta: {
          appBreadcrumb,
          action: aclActions.GET,
          object: [aclObjects.ORGANIZATION, aclObjects.ALL_ORGANIZATIONS]
        }
      },
      {
        path: 'contract-parameters',
        name: views.ORGANIZATIONS_CONTRACT,
        component: OrganizationsManager,
        props: {
          onlyContract: true
        },
        meta: {
          appBreadcrumb,
          action: aclActions.GET,
          object: aclObjects.ORGANIZATION,
          field: aclFields.CONTRACT_PARAMETERS
        }
      },
      {
        path: 'accounts',
        name: views.ORGANIZATIONS_EDIT_ACCOUNTS,
        component: OrganizationsAccounts,
        props: route => ({
          id: route.params.id
        }),
        meta: {
          appBreadcrumb,
          action: aclActions.LIST,
          object: [aclObjects.USER, aclObjects.ALL_USERS]
        }
      },
      {
        path: 'websites',
        name: views.ORGANIZATIONS_EDIT_WEBSITES,
        component: OrganizationsWebsites,
        props: route => ({
          id: route.params.id
        }),
        meta: {
          appBreadcrumb,
          action: aclActions.LIST,
          object: [aclObjects.WEBSITE, aclObjects.ALL_WEBSITES]
        }
      }
    ]
  }
]
